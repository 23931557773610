import { useMantineTheme } from '@mantine/core'

import { getRawColorValue } from '~/utils/helper'

import { useSvgIcons } from './useSvgIcons'

export enum SvgIconType {
  SEARCH = 'search',
  LOGO = 'logo',
  NOTIFICATION = 'notification',
  NEW_NOTIFICATION = 'new_notification',
  NO_USER = 'no_user',
  SAVE_OUTLINE = 'save_outline',
  SAVE_FILLED = 'save_filled',
  PENCIL_ICON = 'pencil_icon',
  TEXT_NOTE_ICON = 'text_note_icon',
  VOICE_NOTE_ICON = 'voice_note_icon',
  WEB_LINK_ICON = 'web_link_icon',
  UPLOAD_ICON = 'upload_icon',
  SAVED_PLACES = 'saved_places',
  SHARE = 'share',
  CHECK_CIRCLE = 'check_circle',
  AUDIO_PLAYER_PLAY = 'audio_player_play',
  AUDIO_PLAYER_PAUSE = 'audio_player_pause',
  SHARE_MENU_ICON = 'share_menu_icon',
  DELETE_MENU_ICON = 'delete_menu_icon',
  DUPLICATE_MENU_ICON = 'duplicate_menu_icon',
  FAVORITE_ON_MENU_ICON = 'favorite_on_menu_icon',
  FAVORITE_OFF_MENU_ICON = 'favorite_off_menu_icon',
  THATCH_LOGO_DARK = 'thatch_logo_dark',
  PROFILE_HOME_ICON = 'profile_home_icon',
  HOME_ICON_OUTLINE = 'home_icon_outline',
  PROFILE_IDENTITY_ICON = 'profile_identity_icon',
  PROFILE_HIRE_ICON = 'profile_hire_icon',
  PLUS_ICON = 'plus_icon',
  LINK_OPEN = 'link_open',
  PENCIL_ICON_SMALL = 'pencil_icon_small',
  BOOKING_LINK_COPY_ICON = 'booking_link_copy_icon',
  SETTINGS = 'settings',
  FOOTER_LINKEDIN = 'footer_linkedin',
  FOOTER_TWITTER = 'footer_twitter',
  MENU_DOTS_ICON = 'menu_dots_icons',
  MENU_DOTS_BOLD_ICON = 'menu_dots_bold_icons',
  ARROW_DOWN = 'arrow_down',
  VIDEO_CAMERA_ICON = 'video_camera_icon',
  ROUTE_ICON = 'route_icon',
  PHOTO_LIBRARY_ICON = 'photo_library_icon',
  STAR_FILLED_ICON = 'star_filled_icon',
  CIRCLE_ICON = 'circle_icon',
  PERSON_SOLID_ICON = 'person_solid_icon',
  LINK_ICON = 'link_icon',
  BIO_ICON = 'bio_icon',
  MARKET_ICON = 'market_icon',
  TAG_ICON = 'tag_icon',
  LOCATION_ICON = 'location_icon',
  INSTAGRAM_ICON = 'instagram_icon',
  TWITTER_ICON = 'twitter_icon',
  YOUTUBE_ICON = 'youtube_icon',
  TIKTOK_ICON = 'tiktok_icon',
  WEBSITE_ICON = 'website_icon',
  CHECK_ICON = 'check_icon',
  LANGUAGES_ICON = 'languages_icon',
  CHECK_ICON_CIRCLE = 'check_icon_circle',
  INFO_ICON = 'info_icon',
  WARNING_ICON = 'warning_icon',
  NEW_TALENT_ICON = 'new_talent_icon',
  NEW_PRO_ICON = 'new_pro_icon',
  RISING_PRO_ICON = 'rising_pro_icon',
  TOP_PRO_ICON = 'top_pro_icon',
  CLOCK_ICON = 'clock_icon',
  RECS_ICON = 'recs_icon',
  CLOSE_ICON = 'close_icon',
  CALENDAR_ICON = 'calendar_icon',
  BIG_CHECKED_CIRCLE = 'big_checked_circle',
  ARROW_RIGHT = 'arrow_right',
  FILTER_ICON = 'filter_icon',
  SMALL_SEARCH = 'small_search',
  SHIELD_ICON = 'shield_icon',
  AVAILABLE_FOR_HIRE_ICON = 'available_for_hire_icon',
  PLAY_ICON = 'play_icon',
  CONSULTATION_ICON = 'headphone_icon',
  ITINERARY_ICON = 'itinerary_icon',
  RECS_SERVICE_ICON = 'recs_service_icon',
  RATING_STAR = 'RATING_STAR',
  SERVICES_LP_CARD_1 = 'services_lp_card_1',
  SERVICES_LP_CARD_2 = 'services_lp_card_2',
  SERVICES_LP_CARD_3 = 'services_lp_card_3',
  SERVICES_LP_CARD_4 = 'services_lp_card_4',
  SERVICES_LP_CARD_5 = 'services_lp_card_5',
  SERVICES_LP_CARD_6 = 'services_lp_card_6',
  SERVICES_LP_CARD_7 = 'services_lp_card_7',
  SERVICES_LP_CARD_8 = 'services_lp_card_8',
  PERSON_SEARCH_ICON = 'PERSON_SEARCH_ICON',
  EDIT_NOTE_ICON = 'person_note_icon',
  UMBRELLA_ICON = 'umbrella_icon',
  GLOBE_SEARCH_ICON = 'globe_search_icon',
  ADD_BADGE_ICON = 'add_badge_icon',
  PICK_ICON = 'pick_icon',
  LOCATION_PIN_ICON = 'location_pin_icon',
  MAP_ICON = 'map_icon',
  CALENDAR_OUTLINED = 'calendar_outlined',
  CAMERA_OUTLINED = 'camera_outlined',
  ARROW_UP_ICON = 'arrow_up_icon',
  THATCH_CHAT_ICON = 'thatch_chat_icon',
  THATCH_ICON = 'thatch_icon',
  MENU_ICON = 'menu_icon',
  TRY_THATCH_BUTTON_ICON = 'try_thatch_button_icon',
  EXPAND_FULL_SCREEN_ICON = 'expand_full_screen_icon',
  COLLAPSE_FULL_SCREEN_ICON = 'collapse_full_screen_icon',
  TABLE_OF_CONTENTS_ICON = 'table_of_contents_icon',
  ENVELOPE = 'envelope',
}

export interface SvgIconProps {
  type: SvgIconType
  width?: number
  height?: number
  fill?: string
  viewBox?: string
  stroke?: string
  strokeWidth?: number
  onClick?: () => void
}

export const SvgIcon: React.FC<SvgIconProps> = ({ type, width, height, viewBox, fill }) => {
  const theme = useMantineTheme()

  const getColor = (value: string) => {
    return getRawColorValue(theme, value)
  }

  const icons = useSvgIcons({ width, height, viewBox, fill: fill ? getColor(fill) : undefined })

  return icons[type]
}
